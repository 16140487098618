<template>
  <card
    ref="cardEl"
    :is-overlayed="isOverlayed"
    :custom-card-class="!title ? 'hide-print' : ''"
    enable-overlay
  >
    <template #header>
      <div class="text-card-header p-card">
        <div class="flex-apart flex-row-actions align-items-center justify-space-between">
          <div>
            <h3
              v-if="title"
              class="h2 text-wrap"
            >
              {{ title }}
            </h3>
          </div>
          <section
            class="filter-popover-boxes filter-popover-btns"
          >
            <button
              v-tooltip="$gettext('Ändra cardet')"
              class="btn btn-primary btn-thin btn-icon-text"
              :tabindex="isOverlayed ? -1 : null"
              @click.prevent="openModal"
            >
              <i class="zmdi zmdi-edit ml-0" /> <span>{{ $gettext('Ändra') }}</span>
            </button>
          </section>
        </div>
      </div>
    </template>
    <template #body>
      <div
        class="tc-card-body"
      >
        <editor-content :content="content" />
      </div>
    </template>
  </card>
</template>

<script setup>
import { computed, ref, onMounted, onUnmounted } from 'vue';
import { useResizeObserver } from '@vueuse/core';
import dynamicImport from 'Utils/dynamicImport';
import { EditorContent } from 'Components/parts/widgets/TipTap';
import Card from 'Components/parts/Card';
import utils from 'Components/parts/widgets/TipTap/utils';
import { store } from '@/store';

const props = defineProps({ card: Object, board: Object, enableOverlay: Boolean });

const cardEl = ref(null);
const observer = ref(null);

const content = computed(() => utils.unSanitizeDoc(props.card.metadata.markdownDescription));
const title = computed(() => props.card.metadata.name);
const isOverlayed = computed(() => store.state.boards.isOrganizing);

onMounted(() => {
  const overlay = cardEl.value.$el.querySelector('.tc-card-overlay-body');
  const cardBody = cardEl.value.$el.querySelector('.tc-card-body');

  observer.value = useResizeObserver(overlay, (entries) => {
    const entry = entries[0];
    cardBody.style.minHeight = `${entry.target.clientHeight}px`;
  });
});

onUnmounted(() => {
  observer.value.stop();
});

const openModal = () => {
  const AddTextDetails = dynamicImport(
    () => import(
      /* webpackChunkName: "AddTextDetails", webpackPrefetch: true */ 'Components/parts/details/AddTextDetails'
    ),
  );

  store.dispatch('openModal', {
    name: 'AddTextDetails',
    component: AddTextDetails,
    dataObject: {
      props: {
        card: props.card,
      },
    },
    size: 'large',
  });
};
</script>
