import Link from '@tiptap/extension-link';
import { Plugin } from 'prosemirror-state';
import { Mark, mergeAttributes } from '@tiptap/core';
import { SITE_URL } from 'Utils/urls';

const isInternalLink = (href) => new URL(href).origin === SITE_URL;

// Pass router to extension, so to be able to do router.push on internal links
const TCLink = (router) => Link.extend({
  name: 'tc-link',
  inclusive: false,
  addOptions() {
    // Enable pasted http links in development
    const protocols = process.env.NODE_ENV === 'development' ? ['http', 'https'] : ['https'];
    return {
      ...this.parent?.(),
      HTMLAttributes: {
        rel: 'noopener noreferrer',
        class: 'link',
      },
      protocols,
    };
  },
  renderHTML({ HTMLAttributes }) {
    return ['a', HTMLAttributes, 0];
  },
  addCommands() {
    return {
      setLink:
          ({ title, href: _href }) => ({ chain, editor }) => {
            let href = _href;
            const pattern = process.env.NODE_ENV === 'development'
              ? /^https?:\/\//i
              : /^https:\/\//i;

            // Convert http to https in production
            if (!pattern.test(href)) href = `https://${href.replace(/^https?:\/\//, '')}`;

            if (editor.isActive(this.name)) {
              // If selection is on existing link, set selection to encompass entire mark
              chain().extendMarkRange(this.name).run();
            }

            chain()
              .command(({ tr, dispatch }) => {
                if (!dispatch) return true;
                const { from, to } = tr.selection;

                tr.insertText(title, from, to);
                tr.addMark(
                  from,
                  from + title.length,
                  editor.schema.marks[this.name].create({
                    href,
                    ...(!isInternalLink(href) && { target: '_blank' }),
                  }),
                );

                return true;
              })
              .extendMarkRange(this.name) // Sets text selection on link
              .run();
          },
      unsetLink:
          () => ({ commands }) => commands.unsetMark(this.name, { extendEmptyMarkRange: true }),
    };
  },
  addProseMirrorPlugins() {
    const name = this.name;
    return [
      new Plugin({
        props: {
          handleClick(view, pos, ev) {
            const clickedNode = view.state.doc.nodeAt(pos);
            const link = clickedNode?.marks.find((m) => m.type.name === name);

            if (link) {
              ev.preventDefault();
              const { attrs: { href } } = link;
              if (isInternalLink(href)) router.push(new URL(href).pathname);
              // If we dont check editable, a readonly editor will open 2 tabs. Not really sure why...
              else if (view.editable) window.open(href);
            }

            return false;
          },
        },
      }),
    ];
  },
});

const FocusHighlight = Mark.create({
  name: 'focus-highlight',
  renderHTML({ HTMLAttributes }) {
    return ['span', mergeAttributes({ class: 'focus-highlight' }, HTMLAttributes), 0];
  },
  parseHTML() {
    return [
      {
        tag: 'span',
      },
    ];
  },
  onUpdate: ({ editor: _editor }) => {
    if (_editor.isActive('focus-highlight')) {
      _editor.chain()
        .clearFocusHighlight()
        .run();
    }
  },
  onBlur({ editor: _editor }) {
    const { empty } = _editor.state.selection;
    if (!empty) {
      _editor.chain()
        .setMeta('preventUpdate', true)
        .setMark(this.name, { extendEmptyMarkRange: true })
        .run();
    } else {
      // Render cursor?
    }
  },
  onFocus({ editor: _editor }) {
    if (_editor.isActive(this.name)) {
      // TODO: Fix this restoring browser focus highlight?
      _editor.chain()
        .clearFocusHighlight()
        .run();
    }
  },
  addCommands() {
    return {
      clearFocusHighlight: () => ({ chain }) => chain()
        .setMeta('preventUpdate', true)
        .command(({ tr, dispatch, state }) => {
          if (!dispatch) return true;
          tr.removeMark(1, state.doc.content.size, this.type);
          return true;
        })
        .run()
      ,
    };
  },
});

export default {
  TCLink,
  FocusHighlight,
};
