<template>
  <div
    ref="cardWrapperEl"
    :class="card?.metadata?.colClass || 'col-xs-12'"
    :data-card-id="props.cardId"
    :data-card-order="card?.order || ''"
    :data-intercom-info="dataIntercomInfo || null"
  >
    <component
      :is="getCardComponent(card)"
      :data-download-card="props.cardId"
      :card="processedCard"
      :class="{
        'hide-all-scrollbars': hideAllScrollbars,
      }"
      :board="board"
      enable-overlay
      is-draggable
      is-in-board
      :links="['clone']"
      :actions="['editButton', 'downloadPng']"
      :context-filter="contextFilter"
      :context-benchmark="contextBenchmark"
      :context-compare="contextCompare"
      @loaded="$emit('card-loaded')"
      @update:col-class="updateColClass"
      @update-drag-area="cardRefreshLayout"
      @download-card-png="setupDownloadCardOpts"
    />
  </div>
</template>

<script setup>
import { computed, ref, onMounted, onBeforeUnmount } from 'vue';
import { klona } from 'klona';
import { throttle, isEmpty } from 'lodash-es';
import { useResizeObserver } from '@vueuse/core';
import * as htmlToImage from 'html-to-image';
import { useRoute } from 'vue-router';
import { captureException } from '@/plugins/sentry';
import { createCard } from 'API/cards';
import { isTopicable } from 'API/topics';
import { CUSTOMER_PLAN_UPGRADE_REASONS } from 'API/customer';
import eventBus from 'Utils/eventBus';
import dynamicImport from 'Utils/dynamicImport';
import { processRecurringDates } from 'Utils/date';
import KeyMetricResult from 'Components/parts/explore/KeyMetricResult';
import QueryResult from 'Components/parts/explore/QueryResult';
import GoalResult from 'Components/parts/explore/GoalResult';
import QueryResultText from 'Components/parts/explore/QueryResultText';
import { store } from '@/store';
import gettext from '@/gettext';

const { $gettext } = gettext;
const route = useRoute();

const props = defineProps({
  board: [Object, null],
  cardId: Number,
  disableDragOuter: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['card-loaded', 'card-refresh-layout']);

const contextFilter = computed(() => {
  const filter = { ...props.board?.metadata?.filter };
  delete filter.benchmark;
  return filter;
});
const contextBenchmark = computed(() => props.board?.metadata?.benchmark || {});
const contextCompare = computed(() => props.board?.metadata?.compare || null);

const cardWrapperEl = ref(null);
const stopObserver = ref(() => {});

const getCardComponent = (card) => {
  if (card?.isKeyMetric !== undefined) {
    if (card.isKeyMetric()) return KeyMetricResult;
    if (card.isGoal()) return GoalResult;
    if (card.isText()) return QueryResultText;
    if (card.isQueryResult()) return QueryResult;
  }
  if (card === null) return 'div';
  console.error('[TC] Couldn’t getCardComponent, was not given a Card Class', card); // eslint-disable-line no-console
  return 'div';
};

const getCardFromStore = (cardId) => {
  if (!cardId) return null;
  const cardIdNumber = parseInt(cardId, 10);
  const cardFromStore = store.getters.getCardById(cardIdNumber);
  if (!cardFromStore) return null;
  return createCard(klona(cardFromStore));
};

const card = computed(() => {
  if (!props.cardId) return null;
  return getCardFromStore(props.cardId);
});

const isSubscription = computed(() => parseInt(route.query.subscription, 10) || false);

const subscriptionTask = computed(() => {
  if (!isSubscription.value) return false;
  const task = store.getters.subscriptionTask(isSubscription.value);
  if (isEmpty(task)) return false;
  return task;
});
const isPrintView = computed(() => useRoute()?.name?.indexOf('print-') > -1);

const processedCard = computed(() => {
  if (!isPrintView.value) return card.value;
  if (!isSubscription.value) return card.value;
  if (!subscriptionTask.value) return card.value;
  const pCard = klona(card.value) || {};
  const { date } = pCard?.metadata?.filter || {};
  const { recurrence_rule } = subscriptionTask.value;
  if (
    !date?.unprocessedDate
    && !isEmpty(subscriptionTask.value)
    && !isEmpty(pCard)
    && !isEmpty(date)
    && recurrence_rule
  ) {
    const processedDates = processRecurringDates(recurrence_rule, date);
    pCard.metadata.filter.date = {
      type: 'absolute',
      ...processedDates,
      unprocessedDate: pCard.metadata.filter.date,
    };
  }
  return pCard;
});

const excludeClassesFilter = (node) => {
  const exclusionClasses = ['hide-print', 'tc-sw-help-btn', 'tc-help-btn', 'hidden'];
  return !exclusionClasses.some((classname) => node.classList?.contains(classname));
};

const openPaywall = () => {
  const GeneralUpgradeDetails = dynamicImport(() => import(/* webpackChunkName: "GeneralUpgradeDetails", webpackPrefetch: true */ 'Components/parts/details/GeneralUpgradeDetails'));
  return store.dispatch('openModal', {
    name: 'GeneralUpgradeDetails',
    component: GeneralUpgradeDetails,
    dataObject: { props: {
      upgradeReason: CUSTOMER_PLAN_UPGRADE_REASONS.unlimited_downloads,
      canStartFreeTrial: store.getters.customerPlanCanStartFreeTrial,
    } },
    size: 'xlarge',
    position: 'center',
  });
};

const hideAllScrollbars = ref(false);
const downloadCard = async (node, options, questionType) => {
  try {
    const dataUrl = await htmlToImage.toPng(node, options);
    let graphType = card.value.metadata.graphType.selected || 'chart';
    if (questionType === 'text' && graphType === 'BarGraph') graphType = `FreeText${graphType}`;
    const cardId = `-${props.cardId ?? card.value.id ?? ''}`;
    const link = document.createElement('a');
    link.download = `Trustcruit-${graphType}${cardId}.png`;
    link.href = dataUrl;
    link.click();
    link.remove();
  } catch (error) {
    captureException('[TC] Couldn’t download card:', error);
    store.dispatch('notify', {
      type: 'slow',
      level: 'error',
      text: $gettext('Kunde inte ladda ner cardet.'),
    });
  } finally {
    store.dispatch('removeDownloadingCard', card.value.id);
    hideAllScrollbars.value = false;
  }
};

const setupDownloadCardOpts = async (questionType) => {
  hideAllScrollbars.value = true;
  if (store.getters.showPaywalls) {
    store.dispatch('removeDownloadingCard', card?.value?.id ?? null);
    openPaywall();
  }
  const waitForAnimation = card.value.metadata.graphType.selected === 'SWRanking' || card.value.metadata.graphType.selected === 'PerformanceScore';
  const node = document.querySelector(`[data-download-card="${props.cardId}"`);
  const options = { filter: excludeClassesFilter, pixelRatio: 2 };
  if (waitForAnimation) setTimeout(() => downloadCard(node, options, questionType), 2000);
  else downloadCard(node, options, questionType);
};

const dataIntercomInfo = computed(() => {
  if (isTopicable(card?.value?.metadata?.question) && card?.value?.metadata?.graphType?.selected) {
    return `text-topics-${card.value.metadata.graphType.selected}`;
  }
  return null;
});

const eventBusCardRefreshLayout = (cardRef, colClassUpdated = false) => eventBus.$emit('card-refresh-layout', { sectionId: cardRef.value?.section ?? null, colClassUpdated });
const cardRefreshLayout = throttle(() => eventBusCardRefreshLayout(card), 20);

const updateColClass = (newColClass) => {
  if (card.value.metadata.colClass === newColClass) return;

  const oldCol = card.value.metadata.colClass;
  const newCard = createCard(klona(card.value));
  newCard.metadata.colClass = newColClass;

  store.dispatch('updateCard', { card: newCard, apiCall: true })
    .catch((err) => {
      newCard.metadata.colClass = oldCol;
      store.dispatch('updateCard', { card: newCard, apiCall: false });
      store.dispatch('notify', {
        type: 'slow',
        level: 'error',
        text: $gettext('Kunde inte spara cardet. %{msg}', { msg: err.response.body.detail }),
      });
    })
    .then(eventBusCardRefreshLayout(card, true));
};

const handleResize = throttle(() => eventBusCardRefreshLayout(card), 30);

onMounted(() => {
  ({ stop: stopObserver.value } = useResizeObserver(cardWrapperEl.value, handleResize));
});

onBeforeUnmount(() => { stopObserver.value(); });
</script>
